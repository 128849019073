<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import simplebar from "simplebar-vue";
import { required } from "vuelidate/lib/validators";


export default {
  page: {
    title: "Not found",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    /* eslint-disable vue/no-unused-components */
    Layout

  },
  data() {
    return {

    };
  },
  validations: {

  },
  methods: {

  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col justify-content-center">
        <div  class="info-component text-center align-middle mt-auto mb-auto">
          <div class="info-component-space">
            <i class="fal fa-search-minus text-danger info-component-icon"></i>
          </div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-lg-12">
              <h3 v-b-tooltip.hover title="Very missing, such empty" class="text-uppercase text-danger">{{$t('error.notfound.title')}}</h3>
              <h5 class="text-muted">{{$t('error.notfound.message')}}</h5>
              <h6 class="text-muted">{{$t('error.notfound.details')}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>